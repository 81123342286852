<template>
  <div class="box box_mm">
    <div class="box_main">
      <div class="search_box">
        <div class="search_item">
          <span style="width: 0.6rem">名称：</span>
          <el-input
            v-model="searchinput"
            placeholder="请输入名称"
            style="width: 2rem"
          ></el-input>
        </div>
        <div class="search_item">
          <span style="width: 1.2rem">提案所属会议：</span>
          <el-select
            v-model="searchhuiyi"
            placeholder="请选择"
            filterable
            clearable
            style="width: 2rem"
            @change="search"
          >
            <el-option
              v-for="item in tahy"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
        <div class="search_item">
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button type="info" @click="reset">重置</el-button>
        </div>
      </div>
      <div class="box_cont">
        <div class="btn_list">
          <!-- <el-button type="primary" @click="goaddnew">新增</el-button> -->
        </div>
        <div style="height: 76vh; box-sizing: border-box">
          <MyTable
            ref="zjlist"
            :tableData="tableData"
            :tableHeader="tableHeader"
            :showbm="true"
            :czwidth="170"
            @getdata="gettable"
          >
            <template slot="btns" slot-scope="{ scope }">
              <el-button @click="look(scope.row)" type="success" size="mini"
                >详情</el-button
              >
              <!-- <el-button v-show="scope.row.zdta==1" type="primary" size="mini" @click="setzd(scope.row,1)">设为推荐重点</el-button>
							<el-button v-show="scope.row.zdta==2" type="warning" size="mini" @click="setzd(scope.row,2)">取消推荐重点</el-button> -->
              <el-button
                v-show="scope.row.zdta == 2"
                type="primary"
                size="mini"
                @click="setzd(scope.row, 3)"
                >汇总重点</el-button
              >
              <el-button
                v-show="scope.row.zdta == 3"
                type="primary"
                size="mini"
                @click="setzd(scope.row, 5)"
                >确认重点</el-button
              >
              <el-button
                v-show="
                  scope.row.zdta == 3 ||
                  scope.row.zdta == 2 ||
                  scope.row.zdta == 4
                "
                type="warning"
                size="mini"
                @click="setzd(scope.row, 4)"
              >
                取消重点
              </el-button>
            </template>
          </MyTable>
        </div>
      </div>
    </div>

    <!--详情-->
    <mydetail
      v-if="showdetail"
      :id="detail_id"
      :showdy="true"
      requsturl="/api/proposa/details"
      @closedetail="showdetail = false"
    >
    </mydetail>
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import mydetail from "@/components/detail_tc.vue";
export default {
  components: {
    mydetail,
    Editor,
    Toolbar,
  },
  data() {
    return {
      showdetail: false,
      detail_id: "",
      searchinput: "",
      searchhuiyi: "",
      tahy: [],
      tableHeader: [
        {
          name: "标题",
          prop: "name",
          width: "300",
        },
        {
          name: "提案类别",
          prop: "lei_name",
          width: "",
        },
        {
          name: "提交人",
          prop: "user_name",
          width: "",
        },
        {
          name: "提交时间",
          prop: "addtime",
          width: "",
        },
        {
          name: "状态",
          prop: "type",
          width: "120",
          type: "tags",
        },
        {
          name: "是否为建议",
          prop: "sfyjtype",
          width: "100",
          type: "tags_isjy",
        },
        {
          name: "是否重点",
          prop: "zdta",
          width: "100",
          type: "tags_zdta",
        },
      ],
      tableData: [],
    };
  },
  mounted() {
    //获取提案会议
    this.$post({
      url: "/api/sshy/index",
      params: {
        p: 1,
        size: 9999,
      },
    }).then((ress) => {
      this.tahy = ress.list;
    });
    this.gettable();
  },
  methods: {
    setzd(item, i) {
      //i--1 设为推荐重点、2 取消推荐重点、3 汇总重点、4 取消汇总重点 5确认重点
      this.$post({
        url: "/api/proposa/qtcz",
        params: {
          ids: item.id,
          zdta:
            i == 1 ? 2 : i == 2 ? 1 : i == 3 ? 3 : i == 5 ? 4 : i == 4 ? 1 : 1,
        },
      }).then((res) => {
        this.$message.success("操作成功");
        this.gettable();
      });
    },
    look(item) {
      this.rowdata = item;
      this.detail_id = item.id;
      this.showdetail = true;
    },
    goaddnew() {
      this.$router.push("/system/add_zhengji");
    },
    addnew() {
      this.$router.push("/system/swiper_add");
    },
    reset() {
      this.searchinput = "";
      this.searchhuiyi = "";
      this.$refs.zjlist.inputpage = 1;
      this.gettable();
    },
    search() {
      this.$refs.zjlist.inputpage = 1;
      this.gettable();
    },
    gettable() {
      this.$post({
        url: "/api/proposa/index",
        params: {
          zdta: "4",
          p: this.$refs.zjlist.inputpage,
          size: this.$refs.zjlist.pagesize,
          sshy: this.searchhuiyi,
          name: this.searchinput,
        },
      }).then((res) => {
        res.list.forEach((item, index) => {
          let cbdw_type = 1; //1未答复、2已答复、3已办理、4、有异议--提案 5、采纳--意见，6、不采纳--意见
          if (item.dfhtype == 1 && item.state == 2) {
            cbdw_type = 4;
          } else if (item.dfhtype != 1) {
            cbdw_type = item.dfhtype;
          } else if (item.dfhtype == 1 && item.sfcn == 0) {
            cbdw_type = 1;
          } else if (item.dfhtype == 1 && item.sfcn == 1) {
            cbdw_type = 5;
          } else if (item.dfhtype == 1 && item.sfcn == 2) {
            cbdw_type = 6;
          }
          item.cbdw_type = cbdw_type;
        });
        this.tableData = res.list;
        this.$refs.zjlist.total = res.count;
      });
    },
    deleterow(row) {
      this.$confirm("确认删除该条数据吗？")
        .then((_) => {
          this.$post({
            url: "/admin/banner/delete",
            params: {
              id: row.id,
            },
          }).then((res) => {
            this.$message.success("删除成功");
            this.gettable();
          });
        })
        .catch((_) => {});
    },
    showsetrole(row) {
      console.log(row);
      this.$refs.mymx.showmx = true;
    },
    changswitch(row) {
      console.log(row);
    },
    showedit(row) {
      this.$router.push({
        path: "/system/swiper_edit",
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box_main {
  background-color: #fff;
  padding: 15px 25px;
  width: 100%;
  box-sizing: border-box;
  min-height: 100%;
}

.search_box {
  display: flex;
  align-items: center;
  font-size: 0.16rem;

  .search_item {
    display: flex;
    align-items: center;
    margin-right: 0.8rem;
  }
}

.box_cont {
  margin-top: 20px;
}

.btn_list {
  display: flex;
  align-items: center;
}

.tc_title {
  font-size: 0.18rem;
  font-weight: bold;
  color: #333;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}

.tc_title:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 4px;
  left: 0px;
  background-color: rgb(26, 144, 253);
}

::v-deep td {
  text-align: center !important;
}

::v-deep .el-table th.el-table__cell > .cell {
  text-align: center !important;
}

::v-deep .el-table th.el-table__cell.is-leaf {
  background-color: rgb(242, 242, 242);
  color: #777;
}

::v-deep .el-table th.el-table__cell.is-leaf,
.el-table td.el-table__cell {
}

::v-deep .el-table td.el-table__cell {
}

::v-deep .el-table {
  margin-top: 10px;
}

::v-deep .el-table__fixed {
}

::v-deep .el-table::before {
  background-color: #fff !important;
}

::v-deep .el-table__fixed::before {
  background-color: #fff !important;
}

::v-deep .el-dialog__header {
  display: flex !important;
}

::v-deep .el-dialog__footer span {
  display: flex !important;
  justify-content: space-around;
  width: 100%;
}
</style>
